import DateFromTo from '@naturehouse/design-system/components/atoms/date-from-to/DateFromTo';
import TextToggle from '@naturehouse/design-system/components/atoms/text-toggle/TextToggle';
import { Observer, Subject } from '@naturehouse/nh-essentials/lib/architecture/ObserverPattern';
import BigQuery from '../../../common/BigQuery';
import { TrackingTimeout } from '../../../common/GoogleAnalyticsEventsBase';
import SearchTrackingEvents from '../../../enums/searchTrackingEvents';
import DatePickerCalendar, {
    DatePickerCalendarEvents
} from '../../../modules/calendar/webComponents/DatePickerCalendar';
import FlexibleDurationPicker from '../../../modules/search/components/FlexibleDurationPicker';
import FlexibleOptions from '../../../modules/search/components/FlexibleOptions';
import StayDurationTypeState, {
    StayDurationType
} from '../../../modules/search/store/StayDurationTypeState';
import { formatDateForUrl } from '../../../util/dateHelper';
import TravelPeriodUIManager from '../../../common/search/travel-period/TravelPeriodUIManager';
import TravelPeriodStateManager from '../../../common/search/travel-period/TravelPeriodStateManager';
import Modal from '../modal/Modal';

class StayDurationInputElement extends HTMLElement implements Observer {
    readonly #modal = new Modal({
        title: this.getAttribute('title') || 'Title',
        classList: ['search-form-modal', 'nh-stay-duration'],
        hidden: true
    });

    readonly #state: StayDurationTypeState = StayDurationTypeState.getInstance();

    readonly #travelPeriodStateManager = TravelPeriodStateManager.getInstance();

    readonly #travelPeriodUIManager = TravelPeriodUIManager;

    #arrivalDepartureInput: DateFromTo | null = null;

    #flexibleDurationTypeInput: HTMLInputElement | null = null;

    #flexibleDurationMonthsInput: HTMLInputElement | null = null;

    #flexibleDurationPicker: FlexibleDurationPicker | null = null;

    #flexibleOptionsInput: FlexibleOptions | null = null;

    #calendarClearButton: HTMLButtonElement | null = null;

    #flexibleClearButton: HTMLButtonElement | null = null;

    #calendar: DatePickerCalendar | null = null;

    #flexibleArrivalInput: HTMLInputElement | null = null;

    constructor() {
        super();
        this.#modal.dataset.for = 'nh-stay-duration';
        this.#travelPeriodStateManager.attach(this.#travelPeriodUIManager);
    }

    public update(subject: Subject): void {
        if (!(subject instanceof StayDurationTypeState)) return;

        this.#switchInputs(subject.type === StayDurationType.CALENDAR);
        this.#determineClearButtonStateFlexibleInput();
        this.#determineClearButtonStateCalendarInput();

        this.#trackCalendarTypeChange();
    }

    protected connectedCallback(): void {
        const template = this.querySelector('#datepicker-calendar-content') as HTMLTemplateElement;
        const footerTemplate = this.querySelector(
            '#calendar-input-modal-footer'
        ) as HTMLTemplateElement;
        const input = this.querySelector('nh-date-from-to') as DateFromTo;
        const flexibleOptionsInput = this.querySelector('nh-flexible-options') as FlexibleOptions;
        const flexibleArrivalInput = this.querySelector(
            '[data-role="flexibleArrivalDate"]'
        ) as HTMLInputElement;

        const calendarClearButton = this.querySelector(
            '[data-role="calendar-clear"]'
        ) as HTMLButtonElement;
        const flexibleClearButton = this.querySelector(
            '[data-role="flexible-clear"]'
        ) as HTMLButtonElement;

        if (
            !template ||
            !input ||
            !footerTemplate ||
            !flexibleArrivalInput ||
            !flexibleOptionsInput ||
            !calendarClearButton ||
            !flexibleClearButton
        ) {
            throw new Error('Templates or inputs not found');
        }

        this.#arrivalDepartureInput = input;
        this.#flexibleOptionsInput = flexibleOptionsInput;
        this.#calendarClearButton = calendarClearButton;
        this.#flexibleClearButton = flexibleClearButton;
        this.#flexibleArrivalInput = flexibleArrivalInput;

        this.#flexibleDurationTypeInput = this.querySelector('[name="flexibleDurationType"]');
        this.#flexibleDurationMonthsInput = this.querySelector('[name="flexibleBookingMonths"]');

        const content = document.importNode(template.content, true);
        const footer = document.importNode(footerTemplate.content, true);
        this.#calendar = content.querySelector('datepicker-calendar') as DatePickerCalendar;

        this.#travelPeriodUIManager.dialog = this.#modal;
        this.#travelPeriodUIManager.travelPeriodInput = this.#arrivalDepartureInput;
        this.#travelPeriodStateManager.checkInDate = input.getAttribute('start');
        this.#travelPeriodStateManager.checkOutDate = input.getAttribute('end');

        this.#modal.content = content;
        this.#modal.footer = footer;

        document.body.append(this.#modal);

        this.#flexibleDurationPicker = this.#modal.content.querySelector(
            'nh-flexible-duration-picker'
        ) as FlexibleDurationPicker | null;

        this.#determineClearButtonStateFlexibleInput();
        this.#determineClearButtonStateCalendarInput();

        this.#addEventListeners();

        this.#state.attach(this);
    }

    #addEventListeners(): void {
        this.#flexibleDurationPicker?.addEventListener('change', this.#onFlexibleDurationChange);

        this.#arrivalDepartureInput?.addEventListener('click', this.#showModal);
        this.#flexibleOptionsInput?.addEventListener('click', this.#showModal);

        this.#flexibleOptionsInput?.addEventListener(
            'change',
            this.#determineClearButtonStateFlexibleInput.bind(this)
        );

        this.#calendar?.addEventListener(
            DatePickerCalendarEvents.DATE_RANGE_SELECTED,
            (event: Event) => {
                const customEvent = event as CustomEvent;
                this.#onCalendarSelect(customEvent);
                this.#determineClearButtonStateCalendarInput();
            }
        );

        this.#arrivalDepartureInput?.addEventListener('cleared', () => {
            this.#calendar?.clearSelection();
            this.#travelPeriodUIManager.resetHighlightSelectedDates();
        });

        const flexibleArrivalSelectorInput = this.#modal.footer.querySelector(
            'input[name="flexibleArrivalDate"]'
        ) as HTMLInputElement | null;

        if (flexibleArrivalSelectorInput) {
            flexibleArrivalSelectorInput.addEventListener('change', () => {
                if (!this.#flexibleArrivalInput) return;
                this.#flexibleArrivalInput.value = flexibleArrivalSelectorInput.value;
                this.#trackFlexibleArrivalDateChange();
            });
        }

        this.#setDoneButtonEvents();
        this.#setClearButtonEvents();

        this.#calendarClearButton?.addEventListener('click', this.#clearCalendarInput.bind(this));
        this.#flexibleClearButton?.addEventListener('click', this.#clearFlexibleInput.bind(this));
    }

    #removeEventListeners(): void {
        this.#flexibleDurationPicker?.removeEventListener('change', this.#onFlexibleDurationChange);

        this.#arrivalDepartureInput?.removeEventListener('click', this.#showModal);
        this.#flexibleOptionsInput?.removeEventListener('click', this.#showModal);

        this.#flexibleOptionsInput?.removeEventListener(
            'change',
            this.#determineClearButtonStateFlexibleInput.bind(this)
        );

        this.#calendarClearButton?.removeEventListener(
            'click',
            this.#clearCalendarInput.bind(this)
        );
        this.#flexibleClearButton?.removeEventListener(
            'click',
            this.#clearFlexibleInput.bind(this)
        );

        this.#flexibleArrivalInput?.removeEventListener(
            'change',
            this.#trackFlexibleArrivalDateChange.bind(this)
        );
    }

    protected disconnectedCallback(): void {
        this.#modal.remove();

        if (!this.#arrivalDepartureInput) {
            return;
        }

        this.#arrivalDepartureInput.removeEventListener('click', this.#showModal);
        this.#flexibleDurationPicker?.removeEventListener('change', this.#onFlexibleDurationChange);

        this.#removeEventListeners();
    }

    readonly #showModal = (): void => {
        if (this.#modal.isOpen || !this.#arrivalDepartureInput || !this.#flexibleOptionsInput) {
            return;
        }

        const input = this.#arrivalDepartureInput.hasAttribute('hidden')
            ? this.#flexibleOptionsInput
            : this.#arrivalDepartureInput;

        this.#modal.open({ bindToElement: input });
    };

    readonly #onFlexibleDurationChange = (): void => {
        if (
            !this.#flexibleDurationPicker ||
            !this.#flexibleDurationTypeInput ||
            !this.#flexibleDurationMonthsInput
        ) {
            return;
        }

        this.#flexibleDurationTypeInput.value = this.#flexibleDurationPicker.type;
        this.#flexibleDurationMonthsInput.value = this.#flexibleDurationPicker.months.join(',');
        this.#flexibleDurationTypeInput.dispatchEvent(new Event('change'));
    };

    #onCalendarSelect(event: CustomEvent): void {
        if (!this.#arrivalDepartureInput) {
            return;
        }

        const detail = event.detail;

        this.#travelPeriodStateManager.updateCheckIn(detail.start);
        this.#travelPeriodStateManager.updateCheckOut(detail.end);

        if (detail.start === null) {
            this.#arrivalDepartureInput.removeAttribute('start');
        }

        if (detail.start) {
            this.#arrivalDepartureInput.setAttribute('start', formatDateForUrl(detail.start));
        }

        if (detail.end === null) {
            this.#arrivalDepartureInput.removeAttribute('end');
        }

        if (detail.end) {
            this.#arrivalDepartureInput.setAttribute('end', formatDateForUrl(detail.end));
        }

        this.#trackDateSelection();
    }

    #switchInputs(isCalendarInput: boolean): void {
        if (!this.#arrivalDepartureInput || !this.#flexibleOptionsInput) return;

        this.#arrivalDepartureInput.classList.toggle('nh-date-from-to--hidden', !isCalendarInput);
        this.#arrivalDepartureInput.toggleAttribute('disabled', !isCalendarInput);

        this.#flexibleOptionsInput.toggleAttribute('hidden', isCalendarInput);
        this.#flexibleOptionsInput.toggleAttribute('disabled', isCalendarInput);
    }

    #determineClearButtonStateCalendarInput(): void {
        if (this.#arrivalDepartureInput === null || this.#calendarClearButton === null) return;

        const toggleValue =
            this.#state.type === StayDurationType.FLEXIBLE ||
            this.#arrivalDepartureInput.value === '';

        this.#calendarClearButton.toggleAttribute('hidden', toggleValue);
    }

    #determineClearButtonStateFlexibleInput(): void {
        if (this.#flexibleOptionsInput === null || this.#flexibleClearButton === null) {
            return;
        }

        this.#flexibleClearButton.toggleAttribute(
            'hidden',
            this.#state.type === StayDurationType.CALENDAR
        );
    }

    #clearCalendarInput(): void {
        this.#arrivalDepartureInput?.dispatchEvent(new Event('clear'));
    }

    #clearFlexibleInput(): void {
        const stayTypeToggle = document.querySelector(
            '[data-role="stay-type-toggle"]'
        ) as TextToggle | null;
        if (stayTypeToggle) {
            stayTypeToggle.value = StayDurationType.CALENDAR;
        }

        this.#state.type = StayDurationType.CALENDAR;
        this.#flexibleDurationPicker?.dispatchEvent(new Event('clear'));
    }

    #trackFlexibleArrivalDateChange(): void {
        const data: GoogleAnalytics4FlexibleArrivalDateEvent = {
            value: Number(this.#flexibleArrivalInput?.value),
            page: window.location.pathname === '/' ? 'homepage' : 'searchpage'
        };

        BigQuery.track({
            eventName: SearchTrackingEvents.FLEXIBLE_ARRIVAL_DATE,
            eventParams: [
                { eventKey: 'value', eventValue: data.value.toString() },
                { eventKey: 'page', eventValue: data.page }
            ]
        });
    }

    #trackCalendarTypeChange(): void {
        const data: GoogleAnalytics4CalendarTypeChangeEvent = {
            type: this.#state.type,
            page: window.location.pathname === '/' ? 'homepage' : 'searchpage'
        };

        BigQuery.track({
            eventName: SearchTrackingEvents.CALENDAR_TYPE,
            eventParams: [
                { eventKey: 'type', eventValue: data.type },
                { eventKey: 'page', eventValue: data.page }
            ]
        });
    }

    #trackDateSelection(): void {
        const value = this.#arrivalDepartureInput?.value;

        if (!value) {
            return;
        }

        const dateRange = JSON.parse(value);

        const data: GoogleAnalytics4DateSelectionEvent = {
            arrival: dateRange.start ?? '',
            departure: dateRange.end ?? '',
            page: window.location.pathname === '/' ? 'homepage' : 'searchpage'
        };

        BigQuery.trackWithDelay(
            {
                eventName: SearchTrackingEvents.DATE_SELECTION,
                eventParams: [
                    { eventKey: 'arrival', eventValue: data.arrival },
                    { eventKey: 'departure', eventValue: data.departure },
                    { eventKey: 'page', eventValue: data.page }
                ]
            },
            TrackingTimeout.DATE_SEARCH_DELAY
        );
    }

    #setDoneButtonEvents(): void {
        const doneButton = this.#modal.footer.querySelector(
            '[data-role="close-modal"]'
        ) as HTMLButtonElement | null;

        if (doneButton) {
            doneButton.addEventListener('click', () => {
                if (this.#modal.isOpen) {
                    this.#modal.close();
                }
            });
        }
    }

    #setClearButtonEvents(): void {
        const clearButton = this.#modal.footer.querySelector(
            '[data-role="clear"]'
        ) as HTMLButtonElement | null;

        if (clearButton) {
            clearButton.addEventListener('click', () => {
                this.#clearCalendarInput();
                this.#clearFlexibleInput();
            });
        }
    }
}

if (!customElements.get('nh-stay-duration')) {
    customElements.define('nh-stay-duration', StayDurationInputElement);
}
