import AbstractSubject from '@naturehouse/nh-essentials/lib/architecture/ObserverPattern';

export enum StayDurationType {
    CALENDAR = 'calendar',
    FLEXIBLE = 'flexible'
}

export default class StayDurationTypeState extends AbstractSubject {
    static #instance: StayDurationTypeState | undefined;

    #type = StayDurationType.CALENDAR;

    set type(value: StayDurationType) {
        if (this.#type === value) return;

        this.#type = value;
        this.notify();
    }

    get type(): StayDurationType {
        return this.#type;
    }

    public static getInstance(initialType?: StayDurationType): StayDurationTypeState {
        if (StayDurationTypeState.#instance === undefined) {
            StayDurationTypeState.#instance = new StayDurationTypeState();
        }

        if (StayDurationTypeState.#instance && initialType !== undefined) {
            StayDurationTypeState.#instance.#type = initialType;
        }

        return StayDurationTypeState.#instance;
    }
}
