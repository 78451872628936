export default class FlexibleOptions extends HTMLElement {
    readonly #typeTranslations = JSON.parse(this.getAttribute('typeTranslations') || '{}');

    readonly #monthsTranslations = JSON.parse(this.getAttribute('monthsTranslations') || '{}');

    readonly #inTranslation = this.getAttribute('inTranslation') || '';

    constructor() {
        super();
        this.attachShadow({ mode: 'open' });
    }

    static get observedAttributes(): string[] {
        return ['disabled'];
    }

    protected connectedCallback(): void {
        this.#inputs.forEach((input) => {
            input.addEventListener('change', this.#onFlexibleOptionsInputChange.bind(this));
        });
        this.#updateTextField();
    }

    protected disconnectedCallback(): void {
        this.#inputs.forEach((input) => {
            input.removeEventListener('change', this.#onFlexibleOptionsInputChange.bind(this));
        });
    }

    protected attributeChangedCallback(): void {
        this.#updateInputs();
        this.#updateTextField();
    }

    get #inputs(): HTMLInputElement[] {
        return Array.from(this.querySelectorAll('input')) as HTMLInputElement[];
    }

    get #typeInput(): HTMLInputElement | null {
        return this.querySelector('input[name="flexibleDurationType"]') as HTMLInputElement | null;
    }

    get #monthsInput(): HTMLInputElement | null {
        return this.querySelector('input[name="flexibleBookingMonths"]') as HTMLInputElement | null;
    }

    get type(): string {
        return this.#typeInput?.value || 'weekend';
    }

    get months(): string[] {
        const input = this.#monthsInput;
        if (!input) return [];

        return input.value.split(',').filter((value) => value);
    }

    #onFlexibleOptionsInputChange(): void {
        this.#updateTextField();
        this.dispatchEvent(new Event('change'));
    }

    #updateInputs(): void {
        this.#inputs.forEach((input) => {
            input.disabled = this.hasAttribute('disabled');
        });
    }

    #updateTextField(): void {
        const typeField = this.querySelector(
            'input[name="flexibleDurationType"]'
        ) as HTMLInputElement | null;

        if (!this.shadowRoot || !typeField) return;

        const type = this.#typeTranslations[this.type] || '';

        const monthsTranslations = this.#monthsTranslations;
        const months = this.months.map((month) => monthsTranslations[month] || '').join(', ');

        const inWord = months.length ? this.#inTranslation : '';

        this.shadowRoot.innerHTML = `${type} ${inWord} ${months}`.trim();
    }
}

if (!customElements.get('nh-flexible-options')) {
    customElements.define('nh-flexible-options', FlexibleOptions);
}
