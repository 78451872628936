import '@naturehouse/design-system/components/atoms/text-toggle/TextToggle';
import type TextToggle from '@naturehouse/design-system/components/atoms/text-toggle/TextToggle';
import StayDurationTypeState, { StayDurationType } from '../store/StayDurationTypeState';

export default class StayDurationTypeToggleConnector {
    readonly #element: TextToggle;

    readonly #state: StayDurationTypeState;

    readonly #updateFilter = (): void => {
        let state = StayDurationType.CALENDAR;

        if (this.#element.value !== '') {
            state = this.#element.value as StayDurationType;
        }

        this.#state.type = state;
    };

    constructor(element: TextToggle, state: StayDurationTypeState) {
        this.#element = element;
        this.#state = state;

        this.#element.addEventListener('change', this.#updateFilter);
    }

    public static connectToElement(querySelector: string): void {
        const element = document.querySelector(querySelector) as TextToggle | null;
        if (!element) return;

        const flexible = element.value === 'flexible';
        const type = flexible ? StayDurationType.FLEXIBLE : StayDurationType.CALENDAR;
        const state = StayDurationTypeState.getInstance();
        state.type = type;

        // eslint-disable-next-line no-new
        new StayDurationTypeToggleConnector(element, state);
    }
}
