import { Observer } from '@naturehouse/nh-essentials/lib/architecture/ObserverPattern';
import type { Subject } from '@naturehouse/nh-essentials/lib/architecture/ObserverPattern';
import type DateFromTo from '@naturehouse/design-system/components/atoms/date-from-to/DateFromTo';
import TravelPeriodStateManager from './TravelPeriodStateManager';

enum HighlightAttributeValues {
    CHECKIN = 'highlight-checkin',
    CHECKOUT = 'highlight-checkout'
}

export class TravelPeriodUIManager implements Observer {
    #dialog: Modal | null = null;

    public get dialog(): Modal | null {
        return this.#dialog;
    }

    public set dialog(dialog: Modal | null) {
        if (dialog) {
            dialog.addEventListener('close', this.resetHighlightSelectedDates);
        }
        this.#dialog = dialog;
    }

    #travelPeriodInput: DateFromTo | null = null;

    public get travelPeriodInput(): DateFromTo | null {
        return this.#travelPeriodInput;
    }

    public set travelPeriodInput(input: DateFromTo | null) {
        if (input) {
            input.addEventListener('click', this.#highlightSelectedDates);
        }
        this.#travelPeriodInput = input;
    }

    readonly #travelPeriodStateManager: TravelPeriodStateManager;

    public constructor() {
        this.#travelPeriodStateManager = TravelPeriodStateManager.getInstance();
    }

    public update(subject: Subject): void {
        if (!(subject instanceof TravelPeriodStateManager)) {
            return;
        }

        if (this.#dialog && this.#dialog.hasAttribute('hidden')) {
            return;
        }

        this.#highlightSelectedDates();
    }

    public readonly resetHighlightSelectedDates = (): void => {
        this.#travelPeriodInput?.removeAttribute(HighlightAttributeValues.CHECKIN);
        this.#travelPeriodInput?.removeAttribute(HighlightAttributeValues.CHECKOUT);
    };

    readonly #highlightSelectedDates = (): void => {
        const { checkInDate, checkOutDate } = this.#travelPeriodStateManager;

        this.#travelPeriodInput?.toggleAttribute(
            HighlightAttributeValues.CHECKIN,
            checkInDate === null || checkOutDate !== null
        );
        this.#travelPeriodInput?.toggleAttribute(
            HighlightAttributeValues.CHECKOUT,
            checkInDate !== null && checkOutDate === null
        );
    };
}

export default new TravelPeriodUIManager();
