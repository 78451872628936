import {
    addToLocalStorage,
    getValueFromLocalStorage
} from '@naturehouse/nh-essentials/lib/localStorage/localStorage';

export type RecentDestinationSearchItem = {
    [key: string]: {
        value: string;
        term: string;
        isKeywordSearch: boolean;
        expiry: number;
    };
};

enum DestinationSearchStoreKey {
    RECENT_DESTINATION_SEARCH = 'recent_destination_search'
}

class DestinationSearchStore {
    get state(): RecentDestinationSearchItem {
        const existingData =
            getValueFromLocalStorage(DestinationSearchStoreKey.RECENT_DESTINATION_SEARCH) || '{}';

        return JSON.parse(existingData);
    }

    set state(value: RecentDestinationSearchItem) {
        const data = JSON.stringify({ ...this.state, ...value });

        addToLocalStorage(DestinationSearchStoreKey.RECENT_DESTINATION_SEARCH, data);
    }
}

export { DestinationSearchStore };
export default new DestinationSearchStore();
