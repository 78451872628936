import BigQuery from '../../../common/BigQuery';
import SearchTrackingEvents from '../../../enums/searchTrackingEvents';
import { formatNumberWithLeadingZero } from '../../../util/dateHelper';

export default class FlexibleDurationPicker extends HTMLElement {
    #choices: HTMLInputElement[] = [];

    get type(): string {
        const selected = this.#choices.find(
            (input) => input.name === 'flex_duration' && input.checked
        );
        return selected?.value || '';
    }

    get months(): number[] {
        return this.#choices
            .filter((input) => input.name === 'flex_month' && input.checked)
            .map((input) => Number(input.value));
    }

    protected connectedCallback(): void {
        this.#choices = Array.from(this.querySelectorAll('input')) as HTMLInputElement[];

        for (const choice of this.#choices) {
            choice.addEventListener('change', this.#trackSearchData.bind(this));
        }

        this.addEventListener('clear', this.#onClear.bind(this));
    }

    protected disconnectedCallback(): void {
        for (const choice of this.#choices) {
            choice.removeEventListener('change', this.#trackSearchData.bind(this));
        }

        this.removeEventListener('clear', this.#onClear.bind(this));
    }

    #onClear(): void {
        this.#choices.forEach((input) => {
            input.checked = input.id === 'flex_duration_weekend';
        });

        this.dispatchEvent(new Event('change'));
        this.#trackSearchData();
    }

    #trackSearchData(): void {
        const durationField = this.#choices.find((i) => i.name === 'flex_duration' && i.checked);
        const months: string[] = [];
        this.#choices.forEach((i) => {
            if (i.name !== 'flex_month' || i.checked === false) {
                return;
            }

            if (Number(i.value) < 10) {
                months.push(formatNumberWithLeadingZero(Number(i.value)));
                return;
            }

            months.push(i.value);
        });

        const data: GoogleAnalytics4FlexibleSearchEvent = {
            duration: durationField?.value ?? '',
            months: months,
            page: window.location.pathname === '/' ? 'homepage' : 'searchpage'
        };

        BigQuery.track({
            eventName: SearchTrackingEvents.FLEXIBLE_SEARCH,
            eventParams: [
                { eventKey: 'duration', eventValue: data.duration },
                { eventKey: 'months', eventValue: data.months.toString() },
                { eventKey: 'page', eventValue: data.page }
            ]
        });
    }
}

if (!customElements.get('nh-flexible-duration-picker')) {
    customElements.define('nh-flexible-duration-picker', FlexibleDurationPicker);
}
